


































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  GetApprovePromoRequestStatusText,
  GetApprovePromoRequestStatusTextEng,
  SetCampaignConditionList,
  DoMatchAllCampaigns,
} from "@/helper/promoCodeHelper";
import {
  PromoDraftingIndexRequest,
  PromoRequestModel,
  PromoCampaignModel,
  PromoCodeRequestDetailModel,
} from "@/model/promoCode/promoCodeModel";
import PromoCodeRequestStatusDetail from "../promoCodeRequestStatus/PromoCodeRequestStatusDetail.vue";
import { namespace } from "vuex-class";
import { LoadingStatusType, PromoStatus } from "../../../enums/enums";
import { NewObject } from "../../../helper/shareHelper";
import AuthService from "@/service/authService";
import IsLoading from "@/components/element/IsLoading.vue";

const auth = new AuthService();
const StorePromoCode = namespace("PromoCode");
const StoreLoading = namespace("Loading");

@Component({ components: { PromoCodeRequestStatusDetail, IsLoading }, name: "PromoCodeRequestDetail" })
export default class PromoCodeRequestDetail extends Vue {
  private LoadingStatusType = LoadingStatusType;
  private PromoStatus = PromoStatus;
  private requestNo: any = "";
  private asanaTask: any = "";
  private ref1: any = "";
  private requestDate: any = "";
  private modalRequestDate = false;
  private creator: any = "";
  private index = 0;
  private allCampaigns = [];
  private status = PromoStatus.None;
  private statusTextThai = "";
  private statusTextEng = "";
  private approveUser = "";
  private quotationNumber = "";
  private invoiceNumber = "";
  private campaignAndCodeCount = {
    campaignTotal: 0,
    totalCode: 0,
    totalQuota: 0,
  };
  private isCampaignDetailLoaded = false;
  private isCanEdit = false;
  private isCanApprove = false;
  private requestId: any = 0;

  get statusColor() {
    switch (this.status) {
      case PromoStatus.None:
        return "white";
      case PromoStatus.Draft:
        return "gray";
      case PromoStatus.WaitingApprove:
        return "amber";
      case PromoStatus.Approve:
        return "light-green";
      case PromoStatus.Void:
        return "red";
      default:
        return "white";
    }
  }

  @StorePromoCode.Getter
  private getPromoRequest!: PromoCodeRequestDetailModel;
  @StorePromoCode.Getter
  private getPromoRequestLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoCampaignList!: Array<PromoCampaignModel>;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;
  @StorePromoCode.Getter
  private getApprovePromoRequestLoading!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoRequest!: (input: PromoDraftingIndexRequest) => void;
  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;
  @StorePromoCode.Action
  private DoApprovePromoRequest!: (input: PromoDraftingIndexRequest) => void;
  @StorePromoCode.Mutation
  private SetPromoRequestLoading!: (status: LoadingStatusType) => void;

  @StoreLoading.Action
  private IsLoading!: (input: boolean) => void;

  get isGetPromoRequestAndGetPromoCampaignListLoadingSuccess() {
    return (
      this.getPromoRequestLoading == LoadingStatusType.Success &&
      this.getPromoCampaignListLoadingStatus == LoadingStatusType.Success
    );
  }

  get isGetApprovePromoRequestLoading() {
    return this.getApprovePromoRequestLoading == LoadingStatusType.Loading;
  }

  created() {
    this.SetPromoRequestLoading(LoadingStatusType.None);
    this.IsLoading(true);
    this.requestId = this.$route.query.requestId ? this.$route.query.requestId : 0;
    const input: PromoDraftingIndexRequest = {
      requestId: this.requestId,
    };
    this.DoGetPromoRequest(input);
    this.DoGetPromoCampaignList();
  }

  private scrollToTop() {
    window.scrollTo({ top: 0 });
  }

  private SetCampaignAndCodeCountData() {
    const rawData = this.getPromoRequest.promoRequest;
    const totalQuotaCount = rawData.reduce((acc: number, currentPromoRequest: Record<string, any>) => {
      const promoCodeArr = currentPromoRequest.promoCode;
      const quotasSum = promoCodeArr.reduce((innerAcc: number, promoCode: Record<string, any>) => {
        return innerAcc + promoCode.quota;
      }, 0);
      return acc + quotasSum;
    }, 0);
    const totalCodeCount = rawData.reduce((acc: number, currentPromoRequest: Record<string, any>) => {
      const promoCodeArrLength = currentPromoRequest.promoCode.length;
      return acc + promoCodeArrLength;
    }, 0);
    this.campaignAndCodeCount = {
      campaignTotal: rawData.length,
      totalCode: totalCodeCount,
      totalQuota: totalQuotaCount,
    };
    document.title = "Request #" + this.requestNo;
  }

  beforeDestroy() {
    document.title = "peakadminexprience";
  }

  @Watch("isGetPromoRequestAndGetPromoCampaignListLoadingSuccess")
  async isGetPromoRequestAndGetPromoCampaignListLoadingSuccessChanged() {
    if (this.isGetPromoRequestAndGetPromoCampaignListLoadingSuccess) {
      this.requestNo = this.getPromoRequest.requestNumber;
      this.asanaTask = this.getPromoRequest.asanaTask ? this.getPromoRequest.asanaTask : "-";
      this.ref1 = this.getPromoRequest.reference1 ? this.getPromoRequest.reference1 : "-";
      this.quotationNumber = this.getPromoRequest.referenceQuotation?.transactionCode
        ? this.getPromoRequest.referenceQuotation.transactionCode
        : "-";
      this.invoiceNumber = this.getPromoRequest.referenceInvoice?.transactionCode
        ? this.getPromoRequest.referenceInvoice.transactionCode
        : "-";
      this.requestDate = this.getPromoRequest.requestDateTime.GetDateFormat();
      this.creator = this.getPromoRequest.requestUser;
      this.allCampaigns = NewObject(this.getPromoRequest.promoRequest);
      this.status = this.getPromoRequest.status;
      this.approveUser = this.getPromoRequest.approveUser ?? "-";
      this.statusTextThai = GetApprovePromoRequestStatusText(this.getPromoRequest.status);
      this.statusTextEng = GetApprovePromoRequestStatusTextEng(this.getPromoRequest.status);
      this.SetCampaignAndCodeCountData();
      DoMatchAllCampaigns(this.getPromoCampaignList, this.allCampaigns);
      this.isCampaignDetailLoaded = true;
      const accessPermission = await auth.getPermissions();
      this.isCanEdit = accessPermission["promoCode"]["draftRequest"];
      this.isCanApprove = accessPermission["promoCode"]["approveRequest"];
      this.SetIsLoadingFalseAfterCompleteRender();
    }
  }

  @Watch("getApprovePromoRequestLoading")
  getApprovePromoRequestLoadingChanged() {
    switch (this.getApprovePromoRequestLoading) {
      case LoadingStatusType.Success:
        this.$router.go(0);
        this.scrollToTop();
        break;
      case LoadingStatusType.Loading:
        this.IsLoading(true);
        break;
      case LoadingStatusType.Fail:
        this.IsLoading(false);
    }
  }

  private GoToEditDraftPromoRequest() {
    this.$router.push({
      name: "promoCodeRequestCreate",
      query: { draftRequestId: this.requestId },
    });
  }

  private ApprovePromoCode() {
    const input = {
      requestId: this.requestId,
    };
    this.DoApprovePromoRequest(input);
  }

  private BackToTableList() {
    this.$router.push({ name: "promoCodeRequest" });
  }

  private SetIsLoadingFalseAfterCompleteRender() {
    this.$nextTick(() => {
      this.IsLoading(false);
    });
  }
}
